/* eslint-disable no-new */
import { shareAction, pageScrollToTarget } from "@shared/scripts/utils/tool";
import Swiper from "swiper";

const createToogleAction = (
  targetSelector,
  modalSelector,
  activeClassName = "is-visible"
) => {
  $(targetSelector).on("tap", e => {
    e.stopPropagation();
    $(modalSelector).toggleClass(activeClassName);
  });
};

export const switchSharedModal = () => {
  createToogleAction(".js-switch-shared", ".js-shared");
  shareAction();
  // wechat
};

export const switchGraphSortModal = () => {
  createToogleAction(".js-switch-graph-modal", "#js-graph-modal");
};

export const switchComment = () => {
  createToogleAction(".js-switch-comment", ".js-comment-container");
};

export const goComment = () => {
  $(".js-go-comment").on("tap", e => {
    e.stopPropagation();
    pageScrollToTarget($("#comment").offset().top, 1000);
  });
};

export const seeMore = () => {
  createToogleAction(".js-column-more", ".js-column-modal");
};

export const switchPostModal = () => {
  createToogleAction(".js-switch-post", ".js-member-post");
};

export const useSwipper = () => {
  new Swiper("#js-member-header", {
    slidesPerView: "auto",
    freeMode: true,
    mousewheel: true,
    initialSlide: $(".u-secondary__item.is-active").index(),
  });
};
