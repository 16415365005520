import React from "react";
import { render } from "react-dom";

import Search from "@components/mobile_search/Search";

const search = () => {
  render(<Search />, document.getElementById("js-search-app"));
};

export default search;
