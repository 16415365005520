const daily = () => {
  const $window = $(window);

  $window.on("scroll", () => {
    if (
      Math.ceil($window.scrollTop() + $window.height() + 30) >
      Math.ceil($("body").height())
    ) {
      $(".js-daily-bar").addClass("is-bottom");
    } else {
      $(".js-daily-bar").removeClass("is-bottom");
    }
  });

  $window.scroll();
};

export default daily;
