import store from "@shared/scripts/utils/store";
import likeAction from "@shared/scripts/features/like_action";

const action = () => {
  $(document).on("click", ".js-like-action", function() {
    if (store.getState().currentUser.info == null) {
      $(".js-signin-modal").addClass("is-visible");
      return;
    }

    likeAction(this);
  });
};

export default action;
