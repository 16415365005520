import { pageScrollToTarget } from "@shared/scripts/utils/tool";

const catalog = () => {
  $(".js-graph-catalog").on("click", function() {
    const targetSelector = $(this).data("target");
    pageScrollToTarget($(targetSelector).offset().top - 55);
    $(".graph-catalog__modal").removeClass("is-visible");
  });
};

export default catalog;
