/* eslint-disable no-alert */
import "./styles/app.scss";
import "jquery";
import "zepto.touch";
import { isWechat } from "mdetect";
import { runPage, timeago, switchTab } from "@shared/scripts/utils/tool";
import "@shared/scripts/features/iconfont";
import "@shared/scripts/features/my.timeago";
import comment from "@shared/scripts/features/comment";
import getCurrentUser from "@shared/scripts/features/get_current_user";
import wechat from "@shared/scripts/utils/wechat";
import action from "./components/add_like_action";
import header from "./components/header";
import home from "./components/home";
import article from "./components/article";
import tips from "./components/tips";
import document from "./components/document";
import category from "./components/category";
import daily from "./components/daily";
import catalog from "./components/catalog";
import joinForm from "./components/member_join_form";
import search from "./components/search";
import switchBaseModal from "./components/switch_base_modal";
import insightModal from "./components/insight_modal";
import insight from "./components/insight";
import {
  switchSharedModal,
  switchComment,
  goComment,
  seeMore,
  useSwipper,
  switchPostModal,
  switchGraphSortModal,
} from "./components/page_actions";

const mobile = () => {
  getCurrentUser();
  if (isWechat()) wechat();

  $.ajaxSetup({
    headers: {
      "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
    },
    error: xhr => {
      alert(xhr.responseText);
    },
  });
  action();
  header();

  const $body = $("body");
  const controllerName = $body.data("controller");
  const isMobile = true;

  switchBaseModal();
  runPage(["articles-show", "dailies-show", "topics-show"], switchSharedModal);
  runPage(["articles-show", "documents-show"], insightModal);
  runPage(
    [
      "articles-show",
      "dailies-show",
      "reports-show",
      "documents-show",
      "documents-detail",
      "topics-show",
      "events-show",
    ],
    () => {
      comment(isMobile);
    }
  );
  runPage(["dailies-show", "reports-show", "documents-show"], switchComment);
  runPage(["users-show", "setting-notice", "insight-home"], () =>
    switchTab("tap")
  );
  runPage("home-index", home);
  runPage("insight-home", insight);
  runPage(["categories-show", "search-index"], category);
  runPage("dailies-show", daily);
  runPage("articles-show", article);
  runPage("articles-show", tips);
  runPage("documents-show", document);
  runPage(
    ["memberships-category", "home-index", "industry-home", "insight-home"],
    timeago
  );
  runPage(["articles-show", "topics-show"], goComment);
  runPage("special_columns-show", seeMore);
  runPage("setting-join", joinForm);
  runPage("search-index", search);
  runPage(["technologies-show", "institutions-show", "experts-show"], () => {
    switchGraphSortModal();
    catalog();
  });

  if (/^memberships$/.test(controllerName)) {
    switchPostModal();
    useSwipper();
  }
};

export default mobile;
