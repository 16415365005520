import React from "react";
import { render } from "react-dom";
import InsightModal from "@components/insight/InsightModal";

export default () => {
  const $openBtn = $(".js-open-insight");

  $openBtn.on("tap", () => {
    $("body").append(
      $(
        '<div class="insight-modal__container js-insight-modal"><div class="insight-modal__content" id="js-modal"></div></div>'
      )
    );
    render(<InsightModal isMobile />, document.getElementById("js-modal"));
  });
};
