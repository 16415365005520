import { getTargetInfo } from "@shared/scripts/features/api";
import { graphTypes } from "@shared/scripts/utils/site_text_constant";

const tips = () => {
  // let timeout = null;
  // const $content = $('#js-article-content');
  const $modal = $("#js-tips-modal");
  const $main = $("#js-tips-main");

  $(".js-switch-tips").on("tap", e => {
    e.stopPropagation();
    $modal.removeClass("is-visible");
  });

  // $(window).on('scroll', () => {
  //   $content.removeClass('is-show-keyword');
  //   clearTimeout(timeout);
  //   // set
  //   timeout = setTimeout(() => {
  //     $content.addClass('is-show-keyword');
  //   }, 5000);
  // });

  $("#js-article-content mark").on("tap", function() {
    const $this = $(this);
    const type = $this.data("type");
    const id = $this.data("id");
    const path = `/graph/${type}/${id}`;

    getTargetInfo(path).done(res => {
      const {
        institutions,
        avatar_url,
        logo_url,
        name,
        summary,
        technologies,
        summary_urls,
        website,
        show_url,
      } = res.data;

      let institutionsImage = "";
      let expertsImage = "";
      if (type === "institutions" && logo_url !== null) {
        institutionsImage = `<div class="graph__logo" style="background-image: url(${logo_url})"></div>`;
      }
      if (type === "experts" && avatar_url !== null) {
        expertsImage = `<div class="graph__avatar" style="background-image: url(${avatar_url})"></div>`;
      }

      let html = `
          <div class="graph__header">MI 信息数据平台 ·<span>${graphTypes[type]}</span></div>
            ${institutionsImage}
            <span>${name}</span>
            <div class="graph__box">
              ${expertsImage}
              <p class="graph__content">${summary}</p>
            </div>`;

      if (type === "institutions" && website !== null && website !== "") {
        html += `
             <a class="graph__title graph__link" href="${website}" target="_blank">
              <i class="iconfont icon-link2 u-mr-10"></i>${website}
            </a>
          `;
      }

      if (
        type !== "institutions" &&
        summary_urls !== null &&
        summary_urls.length > 0
      ) {
        html += `
              <div class="graph__title graph__link graph__origins">
                <i class="iconfont icon-link2 u-mr-10"></i>来源：
            `;

        summary_urls.forEach(item => {
          html += `<a class="graph__origin u-mr-6" href="${item.link}" target="_blank">${item.name}</a>`;
        });
        html += "</div>";
      }

      if (type !== "technologies" && technologies.length > 0) {
        html += `
             <div class="graph__about">
              <div class="graph__title graph__sub">相关技术</div>
              <div class="graph__fields">
          `;
        technologies.forEach(item => {
          html += `<a class="graph__field" href="javascript:;">${item.name}</a>`;
        });
        html += "</div></div>";
      }

      if (type === "experts" && institutions.length > 0) {
        html += `
            <div class="graph__about">
              <div class="graph__title graph__sub">所属机构</div>
              <div class="graph__mechas">
          `;
        institutions.forEach(item => {
          html += `<a class="graph__mecha" href="javascript:;">${item.name}</a>`;
        });
        html += "</div></div>";
      }

      html += `<a class="graph__footer" href=${show_url} target="_blank">查看详情</a>`;

      $main.html(html);
      $modal.addClass("is-visible");
    });
  });
};

export default tips;
