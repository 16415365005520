import insight_swiper from "@shared/scripts/features/insight_swiper";

export default () => {
  insight_swiper();

  const $tabs = $(".js-insight-tabs");
  const offsetTop = $tabs.offset().top;
  const $window = $(window);

  $window.on("scroll", () => {
    const scrollTop = $window.scrollTop();

    $tabs.toggleClass("is-fixed", scrollTop > offsetTop);
  });
};
