import { getDocumentFile } from "@shared/scripts/features/api";

export default () => {
  const $modal = $("#js-document-modal");
  const $modalContent = $("#js-modal-content");
  const $closeBtn = $(".js-close-modal");
  const closeModal = () => $modal.removeClass("is-show");

  $modal.on("tap", closeModal);

  $modalContent.on("tap", event => {
    event.stopPropagation();
  });

  $closeBtn.on("tap", closeModal);

  $("#js-get-document").on("tap", function() {
    getDocumentFile(this.dataset.id);
    $modal.addClass("is-show");
  });
};
