import store from "@shared/scripts/utils/store";
import { openModal } from "@shared/scripts/actions/modal";

const switchBaseModal = () => {
  // $(".sota-signin-modal").addClass("is-visible");
  const $login = $(".sidebar-user__logout");

  if ($login.length === 0) {
    //todo body overflow;
    store.dispatch(openModal("signin"));
    window.popupLogin && $(".js-signin-modal").toggleClass("is-visible");
  } else {
    setTimeout(() => {
      if (window.currentUser && !window.currentUser.mobile_verified) {
        store.dispatch(openModal("signin"));
        $(".js-signin-modal").toggleClass("is-visible");
      }
    }, 1000);
  }

  // const $window = $(window);
  const $sidebar = $(".js-sidebar");

  const sidebarClose = () => {
    if ($sidebar.hasClass("is-visible")) {
      $sidebar.removeClass("is-visible");
      $(".js-switch-siderbar").removeClass("is-active");
    }
  };

  // sidebar
  $(".js-switch-siderbar").on("tap", function(e) {
    e.stopPropagation();
    $sidebar.toggleClass("is-visible");
    $(this).toggleClass("is-active");
  });

  // search modal
  $(".js-switch-search").on("click", e => {
    e.stopPropagation();
    $(".js-search").toggleClass("is-visible");
    sidebarClose();
  });

  // signin modal
  $(".js-switch-sign").on("tap", e => {
    if (
      $(".js-signin-modal")[0].className.includes("is-visible") &&
      window.popupLogin
    ) {
      return false;
    }
    e.preventDefault();
    $(".js-signin-modal").toggleClass("is-visible");
    sidebarClose();
  });

  // forget modal
  $(".js-forget-mask").on("tap", e => {
    e.stopPropagation();
    $(".js-forget-modal").removeClass("is-visible");
  });
};

export default switchBaseModal;
